import "./contact.scss"

import * as React from "react"
import * as particleSettings from "./particlesjs-config.json"

import ContactForm from "../components/ContactForm/ContactForm"
import Layout from "../components/Layouts/Layout"
import Particles from "react-tsparticles"
import { PortfolioCounter } from "../components/PortfolioCounter/PortfolioCounter"
import Seo from "../components/SEO/Seo"
import ServiceBlock from "../components/ServiceBlock/ServiceBlock"
import { StackGrid } from "../components/StackGrid/StackGrid"
import { StaticImage } from "gatsby-plugin-image"
import TestimonialScroller from "../components/TestimonialScroller/TestimonialScroller"
import { loadFull } from "tsparticles"
import { useCallback } from "react"

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        url="https://attaro.co.uk/"
        title="Attaro | We Deliver the Software You Need"
        description="Welcome to Attaro, where we offer and develop a range of enhanced software solutions to take your business to the next level."
      />

      <section className="container content-page-header">
        <div className="row text-center">
          <span className="breadcrumb d-block">Contact</span>
          <h1 className="mb-3">Let’s Talk About Your Project</h1>
          <p>
            Attaro is a UK based software development partner with over 15
            years’ industry experience.
          </p>
        </div>
        <div className="row"></div>
      </section>

      <section className="bg-navy">
      <div className="container container-padding">
          <div className="row justify-content-between white">
            <div className="col-12 col-md-5">
              <hr className="hr-primary mb-4" />
              <h2 className="mb-5">
                For a chat about your project and aspirations get in touch
                today. No cost, no commitment. Let’s see how we can help!
              </h2>
              <h4 className="m-0">Email Us</h4>
              <a
                href="mailto:info@attaro.co.uk"
                className="contact-detail d-block mb-4"
              >
                info@attaro.co.uk
              </a>
              <h4 className="m-0">Call Us</h4>
              <a href="tel:0330120010" className="contact-detail d-block">
                0330 120 0120
              </a>
            </div>
            <div className="col-12 col-md-6 pt-4">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      <section className="container container-padding" id="careers">
      <div className="row">
      <hr className="hr-primary mb-3" />
            <h1 className="mb-4">Careers.</h1>
      </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="mb-4 mt-1">
              Attaro is a small but growing business, boasting a great team of
              individuals who are passionate about what they do.
              <br />
              <br />
              We are currently recruiting for a number of roles and would love
              to hear from you. We offer competitive rates, flexible remote
              working, career progression and a supportive environment.
            </h4>
          </div>
          <div className="col-12 col-md-6 mb-lg-5">
            <h2 className="p-0 mb-0">
              If you are looking for an exciting new challenge, please send your
              CV to{" "}
              <a className="careers-email" href="mailto:martin@attaro.co.uk">
                martin@attaro.co.uk
              </a>{" "}
              for further information.
            </h2>
          </div>
        </div>
      </section>

      
    </Layout>
  )
}

export default ContactPage
